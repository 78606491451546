/* Following https://developer.apple.com/design/human-interface-guidelines/color#System-colors*/

/* From https://css.glass */
:root{
    --bg-color-glass--dark: rgba(77, 72, 72, 0.44);
    --bg-color-glass--darker: rgba(30, 25, 25, 0.60);
    --glass--dark__text-color: rgb(187, 182, 182);
}
.glass--dark {
    /* color-scheme: dark; */
    background: var(--bg-color-glass--dark);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.6px);
    -webkit-backdrop-filter: blur(10.6px);
    border: 1px solid rgba(77, 72, 72, 0.4);
    color: rgb(187, 182, 182);
}
.glass--dark.solid-fill {
    background-color: rgb(44, 43, 42);
}

.glass--darker {
    /* color-scheme: dark; */
    background: var(--bg-color-glass--darker);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.6px);
    -webkit-backdrop-filter: blur(10.6px);
    border: 1px solid rgba(77, 72, 72, 0.4);
    color: rgb(187, 182, 182);
}

.glassText {
    color: var(--glass--dark__text-color);
}

.background {
    background-color: rgb(44, 44, 46);
}