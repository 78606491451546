:root {
  --footer-height: 4.5rem;
  --panel-height: calc(1.1 * 4.5rem);
}

.page-layout {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
  padding-top: var(--nav-height);
}

.footer {
  bottom: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  padding: 5px;
  background-color: var(--bg-color);
}
.footer > .panel {
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height:var(--panel-height);
  padding: 1.5rem;
}
.panel .MuiTypography-root {
  color: var(--link-color);
  text-decoration-color: var(--link-adornment-color);
}

.panel .MuiButton-outlinedSecondary {
  border: 2px solid var(--secondary-color);
  color: var(--glass--dark__text-color);
  opacity: 0.9;
}
.panel .MuiButton-outlinedSecondary:hover {
  border: 2px solid var(--secondary-color);
  opacity: 1;
  background-color: rgba(var(--secondary-color-raw), 0.3);
}

.panel .MuiOutlinedInput-notchedOutline {
  border-color:  rgba(var(--secondary-color-raw), 0.3) !important;
}
/* .panel .MuiOutlinedInput-notchedOutline:hover {
  border-color:  rgba(var(--secondary-color-raw), 1) ;
} */

.yes-button {
  background-color: #4CAF50;
  color: white;
  border-radius: 8px;
  padding: 10px 15px;
  margin-inline: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.yes-button:hover {
  background-color: #45a049;
}

.no-button {
  background-color: #f44336;
  color: white;
  border-radius: 8px;
  padding: 10px 15px;
  margin-inline: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.no-button:hover {
  background-color: #da190b;  
}

.neutral-button {
  background-color: rgb(168, 168, 168);  /*FIXME: Placeholder color*/
  color: white;
  border-radius: 8px;
  padding: 10px 15px;
  margin-inline: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}
.neutral-button:hover {
  background-color: rgb(137, 137, 137);
}

.popup {
  width: 400px;
  background: #fff;
  border-radius: 6px;
  text-align: center;
  padding: 25px 30px 30px;
  color: #333;
  z-index: 1002; 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eduard-logo {
  width: 100px;
}

.render-status {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.api-key-modal__input-label > * {
  color: rgba(var(--secondary-color-raw), 1);
}
.api-key-modal__input-label > *::before{
  /* FIXME: tmp solution to have consistent colour on hover.*/
  border-bottom-color: rgba(var(--secondary-color-raw), 1) !important;
}

.rendering-backdrop__container {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  vertical-align: middle; 
  width: 150px;
  border-radius: 10px;
}