:root {
  --nav-height: 64px;
}
.nav {
  z-index: 10;
  background-color: #121212;
  background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
}
.nav__toolbar {
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem;
}
.nav__title {
  display: flex;
  color: var(--primary-text);
  height: 40px;
  width: auto;
  white-space: nowrap;
  font-size: 1.4em;
}
.nav__subtitle {
  font-size: 0.6em;
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 2px;
}
.nav__logo {
  height: 40px;
  width: auto;
  margin-right: 10px;
}
.nav__content {
  background-color: var(--bg-color);
  height: 100vh;
  width: 100vw;
}
.nav__drawer-btn {
  border-radius: 20%;
  color: var(--secondary-color);
}
.nav__drawer-btn:hover {
  color: var(--primary-text);
  /* border: 1px solid var(--secondary-color); */
}