.settings-panel {
    border-radius: 16px 0px 0px 16px !important;
    box-shadow: 0px -20px 10px 0px inset rgba(0, 0, 0, 0);
    margin-bottom: 0.5rem;
    height: calc(100% - 0.5rem);
    width: 100%;
    z-index: 3;
}

.page-content {
    padding-top: calc(var(--nav-height));
}

.img-preview {
    display: inline-block;
    align-self: center;
    width: 1000px;
}
.map-display {
    position: fixed;
    display: inline-flex;
    overflow: auto;
    max-width: 1000px;
}

/* https://stackoverflow.com/a/70971847*/
/* .map-panel {
    mask-image: linear-gradient(to bottom, transparent 0%, black 48px, black calc(100% - 48px), transparent 100%);
} */
.fill-space {
    width: 100%;
    width: -moz-available;        
    width: -webkit-fill-available;
    width: fill-available;
    height: 100%;
    height: -moz-available;          
    height: -webkit-fill-available;
    height: fill-available;
}

.map-display__overlay {
    align-items: center;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    position: fixed;
    width: 200vw;
    pointer-events: none;
    z-index: 1;
}

.icon-button {
    -webkit-backdrop-filter: blur(13.3px);
    backdrop-filter: blur(13.3px);
    background-color: rgba(40, 39, 39, 1);
    border-radius: 6px;
    border: 1px solid rgba(40, 39, 39, 0.65);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    margin: 10px;
}
  .icon-button:hover{
    background-color: rgba(var(--secondary-color-raw), 0.3);
    /* border: 1px solid var(--link-color); */
}
  .icon-button > .MuiButton-outlinedPrimary {
    background-color: var(--primary-color);
}

.refresh-button {
    background-color: rgba(var(--primary-color-raw), 0.6) !important;
    height: 80px;
    position: fixed;
    top: 50%;
    width: 80px;
    z-index: 2;
    flex-direction: column;
    font-size: 0.7rem;
    text-transform: none;
    color: rgb(229, 233, 237);
}

.refresh-button__icon {
    margin-top: 5px;
    width: 60px;
    height: 60px;
    color: rgb(216, 227, 238);
}

.shading-title {
    height: 30px;
    min-width: 210px;
    background: rgba(var(--bg-color-raw), 0.7);
}
.shading-title > div, input {
    height: inherit;
}
.shading-title__label__empty > label  {
    transform: translate(14px, 5px) scale(1);
    -ms-transform: translate(14px, 5px) scale(1);
    -webkit-transform: translate(14px, 5px) scale(1);
    -moz-transform: translate(14px, 5px) scale(1);
    -webkit-transform: translate(14px, 5px) scale(1);
}
.shading-title__label__empty:focus-within > label  {
    transform: translate(14px, -9px) scale(0.75);
    -ms-transform: translate(14px, -9px) scale(0.75);
    -webkit-transform: translate(14px, -9px) scale(0.75);
    -moz-transform: translate(14px, -9px) scale(0.75);
    -webkit-transform: translate(14px, -9px) scale(0.75);
}
.shading-title__label__empty > legend  {
    min-width: 0.01px;
}