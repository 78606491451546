body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.structure {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-container__root {
  padding-top: calc(var(--nav-height));
  align-self: center;
  max-width: 750px;
}

.details-container__header {
  display: flex;
  justify-content: space-between;
}

.details-container__header>h1 {
  color: #ffffff;
  font-size: 30px;
  flex-shrink: 0;
  font-weight: bold;
}

.details-container__content {
  display: flex;
  flex-direction: column;
  max-height: 85vh;
  overflow-y: scroll;
  margin-bottom: calc(var(--nav-height) + 20px);
  padding-inline: 20px;
  /* background-color: rgb(44, 44, 46); */
}

.displayContainer {
  display: flex;
  justify-content: space-between;
  /* Place them side by side */
  align-items: center;
  width: 100%;
}

.displayBox {
  position: relative;
  width: 250px;
}

.displayBox input {
  text-align: center;
  width: 100%;
  padding: 10px;
  border: 2px solid rgb(0, 0, 0);
  background: rgb(217, 217, 217);
  border-radius: 5px;
  outline: none;
  color: rgb(0, 0, 0);
  font-size: 1em;
}

.displayBox span {
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  transition: 0.5s;
}

.displayBox input:read-only~span {
  color: white;
  transform: translateX(25px) translateY(-7px);
  font-size: 0.65em;
  padding: 0 10px;
  background: #2c2c2e;
  background: #2c2c2e;
  border: 1px solid black;
  border-radius: 5px;
  letter-spacing: 0.2em;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputBox {
  position: relative;
  flex: 1;
  width: 250px;
}

.inputBox input {
  text-align: center;
  width: 100%;
  padding: 10px;
  border: 2px solid rgb(0, 0, 0);
  background: rgb(247, 247, 247);
  border-radius: 5px;
  outline: none;
  color: rgb(0, 0, 0);
  font-size: 1em;
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  transition: 0.5s;
}

.inputBox input:valid~span,
.inputBox input:focus~span {
  color: white;
  transform: translateX(15px) translateY(-7px);
  font-size: 0.65em;
  padding: 0 10px;
  background: #2c2c2e;
  border: 1px solid black;
  border-radius: 5px;
  letter-spacing: 0.2em;
}

.custom-h3 {
  color: #ffffff;
  font-size: 20px;
}

.details-container__button {
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  margin: 10px;
  padding: 10px 20px;
  width: 250px;
}

.details-container__button.navigate {
  background-color: #44b81d;
}

.details-container__button.navigate:hover {
  background-color: #2e7e13;
}

.details-container__button.delete {
  background-color: rgb(224, 101, 100);
}

.details-container__button.delete:hover {
  background-color: rgb(174, 79, 79);
}


.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__popup {
  width: 400px;
  background: #fff;
  border-radius: 6px;
  text-align: center;
  padding: 25px 30px 30px;
  color: #333;
  z-index: 1000;
}

.modal__button {
  color: white;
  border-radius: 8px;
  padding: 10px 15px;
  margin-inline: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal__button.yes {
  background-color: #4CAF50;
}

.modal__button.yes:hover {
  background-color: #45a049;
}

.modal__button.no {
  background-color: #f44336;
}

.modal__button.no:hover {
  background-color: #da190b;
}

.modal__button.neutral {
  background-color: rgb(168, 168, 168);  FIXME: Placeholder color
}
.modal__button.neutral:hover {
  background-color: rgb(137, 137, 137);
}
