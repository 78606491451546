/*
Fade in and out
https://dev.to/selbekk/how-to-fade-in-content-as-it-scrolls-into-view-10j4
*/
.fade-in-section {
  opacity: 0;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.4s ease-out, transform 0.5s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}