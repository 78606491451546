.dropzone, .dropzone > * {
    color: var(--primary-text);
}
.dropzone > svg {
    color: var(--primary-text);
}

.leaflet-container {
    width: 100%;
    height: 100%;
}

.image-zoom-container {
    background-color: var(--bg-color);
    z-index: 0;
}