.base-tile__button {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 160px;
  width: 120px;
  color: var(--primary-text);
}

.base-tile__icon {
  padding: 4px;
  height: 50px;
}

.base-tile__icon>svg {
  font-size: 40px;
}

.base-tile__label {
  height: 20px;
}

.tiles {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.tiles__container {
  display: flex;
  flex-direction: column;
}