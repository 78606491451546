.number-input__root {
  display: grid;
  flex-direction: reverse;
  grid-template-columns: 70% 15px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 6px;
  padding: 2px 1px;
}

.number-input__input {
  text-align: right;
  grid-row: span 2;
  grid-column: 1 / 2;
  color: var(--primary-text);
  background: rgba(var(--bg-color-raw), 0.7);
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  outline: 0;
  padding: 1px;
  padding-right: 8px;
}

.number-input__input:focus-within {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 1px var(--link-color);
}

.number-input__input:hover:not([disabled]) {
  border-color: var(--primary-color);
}

.number-input__input:focus-visible {
  outline: 0;
}

.number-input__button {
  align-items: center;
  appearance: none;
  background: var(--bg-color);
  border: 1px solid var(--secondary-color);
  ;
  box-sizing: border-box;
  color: var(--primary-text);
  display: flex;
  flex-flow: row nowrap;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  grid-column: 2/3;
  height: 15px;
  justify-content: center;
  line-height: 1;
  padding: 0;
  transition-duration: 120ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 15px;
}

.number-input__button:hover:not([disabled]) {
  border-color: var(--primary-color);
  color: var(--primary-color);
  cursor: pointer;
  z-index: 1;
}

.number-input__button:active:not([disabled]) {
  border: 1px solid var(--link-color);
}

.number-input__button.increment {
  transform: translateY(1px);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  grid-row: 1/2;
}

.number-input__button.decrement {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  grid-row: 2/2;
}