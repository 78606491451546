/* Project page */
.projects-page__layout {
  display: flex;
  justify-content: center;
  padding-top: calc(var(--nav-height));
  height: 100%;
}

.projects-page__content {
  display: flex;
  flex-direction: column;
}

/* Project page content */
.projects__header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  min-width: 1037px;
}

.projects__shadow--top {
  box-shadow: 0px 10px 5px 10px var(--bg-color);
  height: 15px;
  margin-right: 15px;
  position: relative;
  z-index: 1;
}

.projects__shadow--bottom {
  box-shadow: -20px -10px 20px 15px var(--bg-color);
  height: 20px;
  margin-bottom: -20px;
  margin-right: 15px;
  position: relative;
  z-index: 1;
  max-width: inherit;
}

.projects__content {
  overflow-y: auto;
}

.projects__content-row {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* Project Card */
.project-card__base {
  color: var(--link-color);
  display: flex;
  flex-direction: column;
  margin-inline: 1rem;
  padding: 1rem;
  text-transform: none;
}

.project-card__image-container {
  width: 280px;
  height: 280px;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.3);
  background-color: var(--bg-color);
  align-content: center;
}

.project-card__image-container>img {
  max-width: 280px;
  max-height: 280px;
}

.project-card__input-label {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  text-align: center;
  z-index: 10;
}